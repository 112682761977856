import {
  BRAZE_BLOG,
  BRAZE_TOPIC,
  BRAZE_BLOG_CATEGORIES,
  BRAZE_BLOG_SLUGS,
  BRAZE_BLOG_TAGS,
  BRAZE_BENEFITS_ANSWERS,
  BRAZE_BENEFITS_QUIZ,
  BRAZE_MARKETING_LANDING,
  BRAZE_LANDING_PAGE_SLUGS,
} from 'braze';
import logger from 'logger/src';

// Braze Web SDK must be dynamically imported due to server side support
// https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#upgrading-the-sdk
export async function initializeBraze(config) {
  try {
    const { initialize, openSession, getUser } = await import('@braze/web-sdk');

    const started = initialize(config.BRAZE_WEB_KEY, {
      baseUrl: config.BRAZE_SDK_URL,
      enableLogging: config.LINGO_ENV !== 'prod',
    });

    if (!started) {
      return null;
    }

    openSession();

    return getUser();
  } catch (error) {
    throw new Error(`Braze initialization failed: ${error.message}`);
  }
}

function updateBrazeBlogUser(brazeUser, brazeCustomUserAttributes) {
  const { blogSlug, blogCategory, blogTags } = brazeCustomUserAttributes;
  brazeUser.addToCustomAttributeArray(BRAZE_BLOG_SLUGS, blogSlug);
  brazeUser.addToCustomAttributeArray(BRAZE_BLOG_CATEGORIES, blogCategory);
  blogTags.forEach((tag) => {
    brazeUser.addToCustomAttributeArray(BRAZE_BLOG_TAGS, tag);
  });
}

function updateBrazeTopicUser(brazeUser, brazeCustomUserAttributes) {
  const { topicSlug } = brazeCustomUserAttributes;
  brazeUser.addToCustomAttributeArray(BRAZE_TOPIC, topicSlug);
}

function updateBrazeBenefitsQuizUser(brazeUser, brazeCustomUserAttributes) {
  const { answers = [] } = brazeCustomUserAttributes;
  answers.forEach((answer, index) => {
    brazeUser.setCustomUserAttribute(`${BRAZE_BENEFITS_ANSWERS}_${index + 1}`, answer.text);
  });
}

function updateBrazeLandingPageUser(brazeUser, brazeCustomUserAttributes) {
  const { pageSlug = '' } = brazeCustomUserAttributes;
  brazeUser.addToCustomAttributeArray(BRAZE_LANDING_PAGE_SLUGS, pageSlug);
}

export async function trackBrazeUser(externalId, brazeCustomUserAttributes) {
  if (externalId == null) {
    throw new Error('Invalid parameters');
  }
  try {
    const { changeUser, getUser, requestImmediateDataFlush } = await import('@braze/web-sdk');

    changeUser(externalId);
    const brazeUser = getUser();

    if (brazeCustomUserAttributes) {
      switch (brazeCustomUserAttributes?.type) {
        case BRAZE_BLOG:
          return updateBrazeBlogUser(brazeUser, brazeCustomUserAttributes);
        case BRAZE_TOPIC:
          return updateBrazeTopicUser(brazeUser, brazeCustomUserAttributes);
        case BRAZE_BENEFITS_QUIZ:
          return updateBrazeBenefitsQuizUser(brazeUser, brazeCustomUserAttributes);
        case BRAZE_MARKETING_LANDING:
          return updateBrazeLandingPageUser(brazeUser, brazeCustomUserAttributes);
        default:
          logger.info('no custom attributes type to update');
      }
    }
    requestImmediateDataFlush();

    return externalId;
  } catch (error) {
    throw new Error(`Braze track user failed for ${error.message}`);
  }
}
