import pino, { type Logger } from 'pino';

let pinoLogger: Logger;

export default pino({
  level: 'info',
});

export function getLogger(correlationId: string) {
  if (pinoLogger == null) {
    pinoLogger = pino({
      level: 'info',
    });
  }

  if (correlationId == null) {
    return pinoLogger;
  }

  return pinoLogger.child({ correlationId });
}
